import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.png";
import { HashLink as Link } from "react-router-hash-link";

const Footer: React.FC = () => {
  return (
    <>
      <div className="footer">
        <div className="logo-footer">
          <img src={logo} alt="Logo" />
          <p className="footer-description">
            At E-Commerce, we believe in more than just transactions; we believe
            in building relationships. Our journey began with a passion for
            [your product category or industry], and today, we are thrilled to
            share that passion with you. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Fusce vehicula bibendum nibh, vel aliquet ante
            pellentesque nec. Fusce est lorem, convallis sit amet convallis a,
            facilisis nec erat. Quisque fringilla malesuada nunc, fermentum
            cursus velit lobortis quis. Vestibulum luctus nulla at ex fermentum
            ornare et at leo. Aenean vel eros dignissim mi cursus lacinia. Sed
            nec sapien tempor ipsum viverra luctus. Quisque tristique sapien
            dolor, ut gravida purus gravida ut.
          </p>
        </div>
        <div className="footer-links">
          <h4>Help</h4>
          <Link to="/" className="footer-item">
            Privacy Policy
          </Link>
          <Link to="/" className="footer-item">
            Shipping & Delivery
          </Link>
          <Link to="/" className="footer-item">
            Refund Policy
          </Link>
          <Link to="/" className="footer-item">
            Track Your Order
          </Link>
        </div>
        <div className="footer-links">
          <h4>Store</h4>
          <Link to="/" className="footer-item">
            Food
          </Link>
          <Link to="/" className="footer-item">
            Receipts
          </Link>
          <Link to="/" className="footer-item">
            Others
          </Link>
        </div>
        <div className="footer-links">
          <h4>Support</h4>
          <Link to="/" className="footer-item">
            Feedback
          </Link>
          <Link to="/" className="footer-item">
            Contact us
          </Link>
          <Link to="/" className="footer-item">
            Download app
          </Link>
          <Link to="/" className="footer-item">
            Terms & condition
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
