import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import {
  faTimes,
  faBars,
  faMagnifyingGlass,
  faHeart,
  faUser,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSearchProduct from "../../hooks/useSearch";
import { searchProducts } from "../../services/SearchService";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string>("");
  const { products, error } = useSearchProduct(query);
  const navigate = useNavigate();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && query.trim()) {
      navigate(`/search?query=${query}`);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -10;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <nav className="nav-container">
        <div className="logo-and-menu">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="menu-toggle" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </div>
        </div>
        <ul className={`nav-links ${isOpen ? "open" : ""}`}>
          <li>
            <Link to="/" className="nav-item">
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/#restaurants"
              scroll={scrollWithOffset}
              className="nav-item"
            >
              Restaurants
            </Link>
          </li>
          <li>
            <Link
              to="/#products"
              scroll={scrollWithOffset}
              className="nav-item"
            >
              Products
            </Link>
          </li>
          <li>
            <Link to="/recipes" className="nav-item">
              Recipes
            </Link>
          </li>
          <li>
            <Link to="/ideas" className="nav-item">
              Ideas
            </Link>
          </li>
        </ul>

        <div className="search-bar">
          <input
            type="text"
            value={query}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Search"
          />
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            onClick={() => query.trim() && navigate(`/search?query=${query}`)}
            className="search-icon"
          />
        </div>
        <div className="icons">
          <a href="#">
            <FontAwesomeIcon icon={faHeart} />
          </a>
          <a href="#">
            <FontAwesomeIcon icon={faUser} />
          </a>
          <a href="#">
            <FontAwesomeIcon icon={faCartShopping} />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
