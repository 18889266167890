import axios from "axios";
import { ProductType } from "../types/Products";

const apiKey = process.env.REACT_APP_PRODUCTS_API_KEY;

export const getProducts = async (
  Page: number,
  Limit: number
): Promise<ProductType> => {
  try {
    const response = await axios.get(
      `https://glutenfreeapp.azurewebsites.net/api/products?page=${Page}&limit=${Limit}&code=${apiKey}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the products:", error);
    throw error;
  }
};

//Get a product by id
export const getProductByID = async (id: number): Promise<ProductType> => {
  try {
    const response = await axios.get(
      `https://glutenfreeapp.azurewebsites.net/api/product/${id}?code=${apiKey}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the product:", error);
    throw error;
  }
};
