import axios from "axios";
import { SearchResponse, ProductType } from "../types/Products";

const apiKey = process.env.REACT_APP_PRODUCTS_API_KEY;

const url = `https://glutenfreeapp.azurewebsites.net/api/products/search?code=${apiKey}`;

export const searchProducts = async (Query: string): Promise<ProductType> => {
  try {
    const response = await axios.post(
      url,
      { Query },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data && Array.isArray(response.data.Items)) {
      return response.data;
    }
    throw new Error("No items found in the response");
  } catch (error) {
    console.error("Error getting products:", error);
    throw new Error("Error fetching products");
  }
};
